<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-table :data="prlductData.content" style="width: 100%" class="m-t-10">
            <el-table-column prop="orderMemberCardLevelRemark" label="VIP卡类型"></el-table-column>
            <el-table-column prop="orderMemberCardStateDays" label="时长"></el-table-column>
            <el-table-column prop="orderMemberCardLevelRemark" label="有效时间">
                <div slot-scope="scope">
                    {{scope.row.orderMemberCardStateBegintime}}--{{scope.row.orderMemberCardStateEndtime}}
                </div>

            </el-table-column>
            <el-table-column prop="date" label="状态" width="150px">
                <div slot-scope="scope" :class="{'c-m':scope.row.balanceChangeFee>0}">
                   {{['待支付','支付成功','失效订单'][scope.row.orderMemberCardPayStatus]}}
                </div>
            </el-table-column>
            <el-table-column prop="date" label="操作" width="150px">
                <div slot-scope="scope" :class="{'c-m':scope.row.balanceChangeFee>0}">
                    <span v-if="scope.row.orderMemberCardPayChanal==0" class="payimg">
                        <img src="@/assets/images/stockCar/WeChat.png" style="margin-right: 5px">微信支付</span>
                    <span v-if="scope.row.orderMemberCardPayChanal==1" class="payimg">
                        <img src="@/assets/images/stockCar/Alipay.png" style="margin-right: 5px">支付宝</span>
                </div>
            </el-table-column>
            <el-table-column prop="orderMemberCardAmount" label="价格" width="120px">
                <div slot-scope="scope">
                    ￥{{scope.row.orderMemberCardAmount}}
                </div>
            </el-table-column>
            <!-- <el-table-column prop="id" label="订单号"></el-table-column> -->
            <el-table-column prop="orderMemberCardPaytime" label="购买时间"></el-table-column>
        </el-table>
        <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { financial } from "@/service"
export default {
    name: "payList",
    components: {},
    data() {
        return {
            type: 0,
            prlductData: {},
            limit: 20,
            start: 0,
            userInfo:localGet('loginUser') || {},
        }
    },
    mounted() {
        this.queryOrderMemberCards()
    },
    methods: {
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.queryOrderMemberCards();
            this.queryOrderMemberCards(this.type)
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.queryOrderMemberCards();
            this.queryOrderMemberCards(this.type)
        },
        // 账单明细
        queryOrderMemberCards() {
            financial.queryOrderMemberCards({
                limit: this.limit,
                start: this.start,
                orderMemberCardPayStatus:1
            }).then(res => {
                this.prlductData = res
            })
        },
    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}
</style>