<template>
    <div style="text-align: left;">
        <div class="member">
            <div class="tabs df-ai-c">
                <div class="tab" :class="select==0?'select':''" @click="select=0">VIP会员</div>
                <div class="tab" :class="select==1?'select':''" @click="select=1">我的会员卡</div>
                <div class="tab" :class="select==2?'select':''" @click="select=2">购卡记录</div>
            </div>
                <VIPMember v-show="select==0"></VIPMember>
                <MyVip v-show="select==1" @back='back'></MyVip>
                <payList v-show="select==2"></payList>

        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { ticket, financial } from "@/service"
import VIPMember from "./components/VIPMember/VIPMember.vue"
import MyVip from "./components/MyVip/MyVip.vue"
import payList from "./components/payList/payList.vue"
export default {
    name: "MemberCard",
    components: {
        VIPMember,
        MyVip,
        payList,
    },
    data() {
        return {
            select:this.$route.query.select||0,
           
        }
    },
    mounted() {


    },
    methods: {
        back(v){
            if(v.type=='setSelect'){
               this.select=v.value 
            }
        }
    }
}
</script>
<style scoped lang="less">
@import "./MemberCard.less";
</style>