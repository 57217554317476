<!-- VIP会员 -->
<template>
    <div class="df-jc-s-b p-30" style="width:100%">
        <div class="MemberCard" v-for="(item,index) in swiperList" :key="index">
            <div class="CardTop">
                <div class="Cards">
                    <div class="swiper-item" :style="item.style">                                
                            <img :src="item['img']" class="cardImg"></img>
                            <span class="name m-l-15" :class="'name'">
                                <span class="left">{{item.name || ""}}</span>
                            </span>
                            <span class="mark m-l-15" :class="'itemText'">{{item.mark || ""}}</span>                                
                    </div>
                </div>
            </div>
            <div class="discounts" v-if="item.itemDetail">
                <div class="top">
                    <div class="left">优惠券专享价</div>
                    <div class="right">{{item.itemDetail.y && item.itemDetail.y.length || 0}}项尊享</div>
                </div>
                <div class="items">
                    <div class="item" v-for="(v, i) in item.itemDetail.y" :key="v.id">
                        <img class="itemImg" :src="fileUrl + v.memberCardTicketIcon" mode=""></img>
                        <div class="name u-line-1">{{v.memberCardTicketName || ""}}</div>
                        <div class="subName">{{v.memberCardTicketRemark || ""}}</div>
                    </div>
                </div>
            </div>
            <div class="discounts" v-else></div>
            <div v-if="item.itemDetail" class="equity">
                <div class="top">{{level[current]}}VIP会员卡权益</div>
                <div class="level">
                    <div v-if="item.itemDetail.x" class="row first">
                        <div class="col">等级特权</div>
                        <div class="col" v-for="(v, i) in item.itemDetail.x" :key="i">
                            {{v.memberCardLevelName}}
                        </div>
                    </div>
                    <div v-if="item.itemDetail.y">
                        <div v-for="(v, i) in item.itemDetail.y" class="row" style="height: 160rpx;" :key="i">
                            <div class="col">{{v.memberCardTicketName}}</div>
                            <div v-for="(childItem, childIndex) in item.itemDetail.x" class="item" :key="childIndex">
                                <div class="item" style="font-size: 28rpx;" v-if="getData(i, childIndex,item.itemDetail).rpice!='0.00'">
                                    {{getData(i, childIndex,item.itemDetail).rpice}}
                                </div>
                                <div class="item" style="font-size: 24rpx;" v-if="getData(i, childIndex,item.itemDetail).num>0">
                                    {{getData(i, childIndex,item.itemDetail).num}} 张
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.itemDetail.x">
                        <div v-if="item.itemDetail.x" class="row">
                            <div class="col">价值(￥)</div>
                            <div class="col" v-for="(v, i) in item.itemDetail.x" :key="i">
                                {{v.memberCardTicketValue}}
                            </div>
                        </div>
                        <div v-if="item.itemDetail.x" class="row noBorder">
                            <div class="col">价格(￥)</div>
                            <div class="col" v-for="(v, i) in item.itemDetail.x" :key="i">
                                {{v.memberCardLevelPrice}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="0">
                <div class="freight">
                    <div class="line"></div>
                    <div class="name">虎年运费补贴活动权益</div>
                    <div class="line RLine"></div>
                </div>
                <div class="rowBox">
                    <div class="text">你卖货，我包邮</div>
                    <div class="explain">虎年活动规则说明：</div>
                    <div class="explainText">1：VIP买家专属福利</div>
                    <div class="explainText">2：消费VIP工厂商品</div>
                    <div class="explainText">3：平台交易，扣交易额{{setTings.ratio_platform ? (setTings.ratio_platform * 100).toFixed(0) : "xx"}}%作为佣金</div>
                    <div class="explainText">4：买家和工厂各返{{setTings.ratio_seller ? (setTings.ratio_seller * 100).toFixed(0) : "xx"}}%消费券</div>
                    <div class="explainText">5：兑换方式详情看官网标签</div>
                </div>
                <div class="bottom">
                    <div class="row">
                        <div class="col title">举例：</div>
                        <div class="col">通过平台交易</div>
                        <div class="col">10000元</div>
                    </div>
                    <div class="row">
                        <div class="col title">平台扣点：</div>
                        <div class="col" style="width: calc(100% - 100% / 3);">10000×5%等于500元</div>
                    </div>
                    <div class="tab">
                        <div class="tabRow">
                            <div class="col title">对象：</div>
                            <div class="col">返还工厂</div>
                            <div class="col">补贴买家</div>
                        </div>
                        <div class="tabRow">
                            <div class="col title">金额：</div>
                            <div class="col">500元</div>
                            <div class="col">500元</div>
                        </div>
                        <div class="tabRow">
                            <div class="col title">使用说明：</div>
                            <div class="col">次月返还</div>
                            <div class="col">消费兑换</div>
                        </div>
                        <div class="tabRow" style="border: none;">
                            <div class="col title">返还方式：</div>
                            <div class="col">通用消费券</div>
                            <div class="col">通用消费券</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="insetBottom"></div>
        </div>
    </div>
</template>
<script>
    import oucy from "@/util/oucyUtil";

    import { localSet, localGet, localDel } from "@/store/store"
    import { ticket, financial } from "@/service"
export default {
    name: "VIPMember",
    props: {
    },
    data() {
        return {

            level: ["家具", "物流", "设计师"],
            current: 1, //当前卡片
            fileUrl: oucy.ossUrl,
            swiperList: [{}], //滑块图片
            bgUrl: [], //滑块相应的背景图片
            memberCardTypeCover: {}, //滑块相应的权益图片
            card: [],
            cardId: null,
            itemDetail: null,
            setTings: { //活动设置
                ratio_buy: "xx", //  买家分配比例  number(double)  
                ratio_platform: "xx", // 平台扣除比例  number(double)  
                ratio_seller: "xx", //   卖家分配比例
            },
        }
    },
    mounted() {
       this.getMemberCardUseList()
       this.getMemberCardTypeList()

       // this.queryActivity4TigerVipSubsidy() 
    },
    methods: {

        getMemberCardUseList() {
            financial.getMemberCardUseList({}).then(res => {
                this.CardUse = res
            })
        },
        getData(index, childIndex,itemDetail) {
            let tickets = itemDetail.x[childIndex].memberCardTicketBasicForNumList;
            let chooseData = null;
            if (tickets && tickets.length > 0) {
                for (var i = 0; i < tickets.length; i++) {
                    if (tickets[i].id == itemDetail.y[index].id) {
                        chooseData = tickets[i];
                        break;
                    }
                }
            }
            if (chooseData) {
                return {
                    rpice: chooseData.memberCardTicketFee,
                    num: chooseData.cardTicketAmount
                }
            } else {
                return {
                    rpice: "0.00",
                    num: "0"
                }
            }
        },
        //滑动卡片动画结束
        animationfinish(e) {
            this.current = e.detail.current
            this.cardId = this.card[this.current].id
            this.queryCardLevelTicket()
        },
        //点击滑动卡片
        itemClick(v) {
            // this.linkTo("/MemberCard/CardBenefits/CardBenefits?current=" + (this.current || 0))
        },
        linkTo(url) {
            // uni.navigateTo({
            //     url
            // })
        },
        //查询会员卡类型列表
        getMemberCardTypeList(url) {
            // url = this.$postUrl.getMemberCardTypeList;
            financial.getMemberCardTypeList().then(res => {
                this.swiperList = []
                this.bgUrl = []
                for (let v of res) {
                    v.memberCardTypeUrl = JSON.parse(v.memberCardTypeUrl)
                    this.swiperList.push({
                        id: v.id,
                        img: this.fileUrl + v.memberCardTypeUrl.cover,
                        name: v.memberCardTypeTitle,
                        mark: v.memberCardTypeRemark,
                        itemDetail: null,
                        style:{color:v.memberCardFontColor}
                    })
                    this.bgUrl.push(this.fileUrl + v.memberCardTypeUrl.back)
                    //如果有权益图片，则添加到相对应的id里
                    v.memberCardTypeCover && (this.memberCardTypeCover[v.id] = this.fileUrl + v
                        .memberCardTypeCover);
                }
                for (let v of this.swiperList) {
                    this.queryCardLevelTicket(v)
                }
                this.card = res
                this.cardId = res[this.current].id
                // this.queryCardLevelTicket()
            })
        },
        //查询会员卡等级对应的优惠卷
        queryCardLevelTicket(v = {}) {
            let data = {
                cardTypeId: v.id || this.cardId,
            }
            financial.queryCardLevelTicket(data).then(res => {
                if (res) {
                    if (v.id) {
                        v.itemDetail = res
                    } else {
                        this.itemDetail = res
                    }
                } else {
                    this.itemDetail = null
                }
            })
        },
        //查询虎年vip补贴活动设置
        queryActivity4TigerVipSubsidy(url) {
            // url = this.$postUrl.queryActivity4TigerVipSubsidy;
            financial.queryActivity4TigerVipSubsidy().then(res => {
                this.setTings = res || {}
            })
        },        
    },
}
</script>
<style scoped lang="less">
    .MemberCard {
        //主体内边距
        .mPadding {
            padding: 30px 40px;
        }
        //行内居中
        .rowCenter {
            display: flex;
            align-items: center;
        }
        .flexBetween{
            .rowCenter;
            justify-content: space-between;
        }
        .flexCenter{
            .rowCenter;
            justify-content: center;
        }
        .borderBottom {
            border-bottom: 1px solid #EFEFEF;
        }
        .CardTop {
            /*width: 100vw;*/
            /*height: 220px;*/
            /*margin-bottom: 100px;*/
            position: relative;
            .bgImg {
                width: 100%;
                height: 100%;
            }
            .swiper-item {
                text-align: center;
                color: #535967;
                position: relative;
            }
            .name{
                position: absolute;
                left: 16px;
                top: 16px;
                font-size: 21px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
            }
            .mark{
                position: absolute;
                left: 16px;
                top: 54px;
                font-size: 12px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
            }
        }
        .discounts {
            width: 350px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
            /*margin: 15px 20px;*/
         background: #EFEFEF;
            border-radius: 10px;
            overflow: hidden;
            .top {
                height: 41px;
                padding: 0 15px;
                .flexBetween;
                color: #666;
                .left {
                    font-size: 15px;
                }
                .right {
                    font-size: 14px;
                }
            }
            .items {
                background: #F7F7F7;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 20px;
                .item {
                    width: 100% / 3;
                    margin-top: 20px;
                    .flexCenter;
                    flex-direction: column;
                    padding: 25px 5x 0;
                    .itemImg {
                        width: 60px;
                        height: 60px;
                    }
                    .name {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        color: #333;
                        margin: 2px 0;
                    }
                    .subName {
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #aaa;
                    }
                }
            }
        }
        .detailBtn {
            height: 45px;
            .flexCenter;
            background: #E5C590;
            border-radius: 21px;
            margin: 550x 20px 50px;
        }
        .detailBtn1 {
            background: #333;
            color: #E5C590;
        }
        .detailBtn2 {
            background: #99A5BC;
            color: #fff;
        }
        .equity {
            border-radius: 5px;
            margin: 15px 20px;
            overflow: hidden;
            .top {
                .flexCenter;
                font-family: PingFang SC;
                font-weight: bold;
                background: #EFEFEF;
                height: 40px;
                font-size: 14px;
            }
            .level {
                background: #F7F7F7;
                .first {
                    color: #E5C590;
                    font-weight: bold;
                }
                .row {
                    .flexBetween;
                    /*font-size: 14px;*/
                 .borderBottom;
                    .col, .item {
                    font-size: 12px;
                        .flexCenter;
                        flex: 1;
                        height: 50px;
                    }
                    .item {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .noBorder {
                    border: none;
                }
            }
        }
        .freight {
            .flexCenter;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333;
            margin-top: 30px;
            .line {
                width: 82px;
                height: 3px;
                background: linear-gradient(90deg, #F7F7F7 0%, #E5C590 100%);
                opacity: 0.4;
                border-radius: 25px;
            }
            .RLine {
                background: linear-gradient(270deg, #F7F7F7 0%, #E5C590 100%);
            }
            .name {
                margin: 0 10px;
            }
        }
        .text {
            .flexCenter;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333;
        }
        .rowBox {
            padding: 0 20px 15px;
            .explain {
                padding: 18px 0 15px;
            }
            .explainText {
                padding: 5px 0;
            }
        }
        .bottom {
            .mPadding;
            .row {
                .flexBetween;
                padding-bottom: 20px;
                .title {
                    font-family: PingFang SC;
                    font-weight: bold;
                }
                .col {
                    font-family: PingFang SC;
                    width: 100% / 3;
                }
            }
            .tab {
                border: 1px solid #aaa;
                border-radius: 5px;
                .tabRow {
                    padding: 15px 10px;
                    .flexBetween;
                    border-bottom: 1px solid #aaa;
                    .title {
                        font-family: PingFang SC;
                        font-weight: bold;
                    }
                    .col {
                        width: 100% / 3;
                    }
                }
            }
        }
    }
    .MemberCard{
        flex: 1;
    }
    .cardImg{
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }


</style>