<template>
    <div class="MyVip">
        <template v-if="cardDetail.memberCardTypeName">
            <div class="title0"><img src="@/assets/icon/user.png" class="icon" alt="">已有会员卡</div>
            <div class="cardLevel">
                <img class="cardIng" :src="fileUrl + cardDetail.memberCardLevel_all.memberCardLevelImage" mode="aspectFit" v-if="cardDetail.memberCardLevel_all"></img>
                <div class="cardName">{{cardDetail.memberCardTypeName || ""}}</div>
                <div class="date">会员到期：{{cardDetail.memberCardUserTime || "----年--月--日 --:--:--"}}</div>
                <div class="price">
                    此卡总价值{{cardDetail.memberCardLevel_all && cardDetail.memberCardLevel_all.levelPrice || 0}}元</div>
                <div class="renew">
                    ￥{{cardDetail.memberCardLevel_all && cardDetail.memberCardLevel_all.memberCardLevelPrice || 0}}</div>
            </div>
            <div class="freight" v-if="cardDetail.memberCardLevel_all && cardDetail.memberCardLevel_all.ticketBasicForNums">
                <div class="item" v-for="(v, i) in cardDetail.memberCardLevel_all.ticketBasicForNums" :key="i">
                    <img class="iconImg" :src="fileUrl + v.memberCardTicketIcon" mode=""></img>
                    <div class="side">
                        <div class="name">{{v.memberCardTicketName || ""}}</div>
                        <div class="num">{{v.memberCardTicketRemark || ""}}×{{v.cardTicketAmount || 0}}</div>
                    </div>
                </div>
            </div>
            <div class="title title0" v-if="cardDetail.nextMemberCardLevel && cardDetail.nextMemberCardLevel.ticketBasicForNums && cardDetail.nextMemberCardLevel.ticketBasicForNums.length"><img src="@/assets/icon/fj.png" class="icon" alt="">会员成长</div>
            <div class="discounts" v-if="cardDetail.nextMemberCardLevel && cardDetail.nextMemberCardLevel.ticketBasicForNums && cardDetail.nextMemberCardLevel.ticketBasicForNums.length">
                <div class="top">
                    <div class="left">会员升级后可获得如下奖励</div>
                    <div class="right upgradeNow" @click="gotoClubCard()">
                        立即升级</div>
                </div>
                <div class="items">
                    <div class="item" v-for="(v, i) in cardDetail.nextMemberCardLevel.ticketBasicForNums" :key="i">
                        <img class="itemImg" :src="fileUrl + v.memberCardTicketIcon" mode="aspectFit"></img>
                        <div class="name u-line-1">{{v.memberCardTicketName || ""}}</div>
                        <div class="subName">{{(v.memberCardTicketRemark || "") + " x " + (v.cardTicketAmount || 0)}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="text-center">
        	<div class="c-2 m-t-100">您还没有任何会员卡</div>
        	<el-button type="primary" class="m-t-20" size="mini" @click="back">去选一个</el-button>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { ticket, financial } from "@/service"

export default {
    name: "MyVip", //我的VIP会员
    data() {
        return {
            tickets: [],
            cardDetail: {},
            fileUrl: oucy.ossUrl,
            userInfo: localGet('loginUser') || {},
        }
    },

    mounted() {

        this.getMemberCardUseList();
    },
    methods: {
        //查询用户会员卡
        getMemberCardUseList() {
            financial.getMemberCardUseList().then(res => {
                this.cardDetail = res || {}
            })
        },
        back() {
        	this.$emit('back',{type:'setSelect',value:0})
        },
        gotoClubCard(){
            oucy.go('/clubCard')
        },
    }
}
</script>
<style lang="less" scoped>
@import "./MyVip.less";
</style>